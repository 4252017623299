.hero-container {
  padding: 2rem 4rem;
  background-size: cover;
  color: #fff;
  height: fit-content;

  &__title {
    font-size: 2.5rem;
    width: 50%;
  }

  .waiting-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    .launch {
      .title {
        background: linear-gradient(87.75deg, #ad782d 1.89%, #e2c75b 96.74%);
        border-radius: 15px;
        width: 520px;
        padding: 1.8rem 0;
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 2.5rem;
      }
      .text {
        font-size: 1.4rem;
        width: 80%;
        margin: 0.5rem 0;
      }
      img {
        width: 100%;
      }
    }
    &__join {
      background: #1a1e26;
      backdrop-filter: blur(64px);
      border-radius: 30px;
      border: 1px solid #e2c75b;
    }
  }
  .hero-decoration {
    margin-top: 2rem;
    width: 200px;
  }

  @media screen and (max-width: 1220px) {
    &__title {
      font-size: 2rem;
      line-height: 3rem;
      width: 100%;
      text-align: center;
    }
    .waiting-list {
      flex-direction: column;
      justify-content: flex-start;

      .launch {
        .title {
          background: linear-gradient(87.75deg, #ad782d 1.89%, #e2c75b 96.74%);
          border-radius: 15px;
          width: 400px;
          padding: 1.2rem 0;
          font-size: 1.8rem;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 6rem;
        }
        .text {
          font-size: 1.4rem;
          width: 100%;
          margin: 0.5rem 0;
          text-align: center;
        }

        .text.last-text {
          margin-bottom: 3rem;
        }
        img {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    width: 75%;
    margin: 0 auto;
  }
  @media screen and (min-width: 1800px) {
    &__title {
      font-size: 2.5rem;
      width: 30% !important;
    }
    .waiting-list {
      margin-top: -3rem;
    }
    .launch {
      display: flex;

      .text {
        text-align: center;
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: 645px) {
    padding-inline: 1rem;
    .waiting-list {
      .launch {
        .title {
          width: 350px;
          font-size: 1.2rem;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .waiting-list {
      .launch {
        .title {
          width: 100%;
          font-size: 1rem;
        }
      }
    }
  }
}

.hugerewards-container {
  color: #fff;
  padding: 0 4rem 6rem;
  position: relative;

  &__steps {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem 1.4rem;

    &__step {
      height: 300px;
      background: #1f242d;
      padding-inline: 2rem;
      border-radius: 24px;
      width: 65%;

      &--right {
        align-self: flex-end;
      }

      &__title-icon {
        transform: translateX(-1.4rem);
      }
      &__title {
        font-size: 1.6rem;
      }

      &__body {
        width: 80%;
        line-height: 1.6rem;
        color: #9ba5b2;
        margin-top: 1rem;
      }
    }
    .decoration1 {
      position: absolute;
      transform: translateY(185%);
      z-index: -1;
      width: 200px;
    }
    .decoration2 {
      right: 7%;
      position: absolute;
      transform: translateY(400%);
      z-index: -1;
      width: 200px;
    }
  }

  @media screen and (max-width: 880px) {
    padding: 6rem 2rem;
    &__steps {
      &__step {
        border-radius: 20px;
        padding-bottom: 3.5rem;
      }
    }
  }

  @media screen and (max-width: 720px) {
    padding: 6rem 1rem;
    .decoration1 {
      display: none;
    }
    .decoration2 {
      display: none;
    }
  }
  @media screen and (max-width: 425px) {
    &__steps {
      &__step {
        height: 300px;
        padding-bottom: 3rem;

        &__title-icon {
          width: 60%;
        }

        &__title {
          font-size: 1.4rem;
        }

        &__body {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    &__steps {
      &__step {
        height: 300px;
        padding-bottom: 2rem;

        &__title-icon {
          width: 60%;
        }

        &__title {
          font-size: 1.3rem;
        }

        &__body {
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    width: 75%;
    margin: 0 auto;
  }
}

.visit {
  margin: 5rem 0;
  .link {
    font-size: 1rem;
    width: 80%;
    background: linear-gradient(87.75deg, #ad782d 1.89%, #e2c75b 96.74%);
    border-radius: 0.5rem;
    padding: 1.2rem 0;
    display: block;
    text-align: center;
    text-decoration: none;
    color: #fff;
    margin: 0 auto;
  }

  @media screen and (min-width: 375px) {
    .link {
      width: 300px;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 7rem 0;
  }

  @media screen and (min-width: 1024px) {
    margin: 10rem 0;
    .link {
      font-size: 1.4rem;
      width: 400px;
    }
  }

  @media screen and (min-width: 1440px) {
    .link {
      width: 500px;
    }
  }
}

.join-container {
  background-color: #22253ba4;
  background-image: url("../../assets/backgrounds/7-dark@tinypng.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  padding: 10rem 4rem;

  &__text {
    margin: 0 auto;
    font-size: 1.6rem;
    line-height: 2.7rem;
    color: #ccd4df;
    text-align: center;
    width: 90%;
  }

  &__button {
    display: block;
    width: fit-content;
    text-decoration: none;
    margin: 0 auto;
    margin-top: 3rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    background: linear-gradient(87.75deg, #ad782d 1.89%, #e2c75b 96.74%);
    box-shadow: 0px 10px 20px rgba(226, 199, 91, 0.05),
      0px 10px 20px rgba(226, 199, 91, 0.05);
    color: #fff;
    border-radius: 4px;
  }

  @media screen and (min-width: 1440px){
    &__text {
      width: 60%;
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 7rem 2rem;
    &__text {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
  @media screen and (max-width: 550px) {
    &__text {
      width: 100%;
      font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 375px) {
    &__text {
      width: 100%;
      font-size: 1.2rem;
    }

    &__button {
      padding: 1rem 1.8rem;
      font-size: .8rem;
    }
  }

  @media screen and (max-width: 280px) {
    &__text {
      width: 100%;
      font-size: 1.2rem;
    }

    &__button {
      padding: 1rem 1rem; 

      font-size: 1rem;
    }
  }
}

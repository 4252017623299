.mobile-apps {
  // background: #121620;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 0 4rem 6rem;

  .mobile-apps__download {
    .heading {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    .text {
      line-height: 1.6rem;
      font-size: 1.2rem;
      margin-bottom: 2rem;
      width: 80%;
      color: #ccd4df;
    }

    .download-button {
      display: flex;
      align-items: center;
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 0.5rem;
      margin-bottom: 0.8rem;
      gap: 10px;
      width: 80%;
      place-content: center;
      text-decoration: none;
      color: #fff;
    }

    .download-button--secondary {
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.05);
    }
    align-self: center;
  }

  .mobile-apps__image {
    width: 50%;
  }

  @media screen and (min-width: 720px) and (max-width: 900px) {
    .mobile-apps__download {
      .heading {
        font-size: 1.4rem !important;
        margin-bottom: 1rem;
      }

      .text {
        line-height: 1rem;
        font-size: 0.8rem;
        margin-bottom: 1.5rem;
        width: 80%;
      }

      .download-button {
        padding: 1rem;
        border-radius: 0.5rem;
        margin-bottom: 0.8rem;
        width: 80% !important;
        font-size: 0.8rem;
      }
    }
    .mobile-apps__image {
      width: 60%;
      transform: translateX(2rem);
    }
  }

  @media screen and (max-width: 1024px) {
    padding-inline: 4rem;

    .mobile-apps__download {
      .heading {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }

      .text {
        line-height: 1.4rem;
        font-size: 1rem;
        margin-bottom: 1.5rem;
        width: 80%;
      }

      .download-button {
        padding: 1rem;
        border-radius: 0.5rem;
        margin-bottom: 0.8rem;
        width: 70%;
        font-size: 0.8rem;
      }
    }
    .mobile-apps__image {
      width: 40%;
    }
  }
  @media screen and (max-width: 700px) {
    padding: 0 1rem 0;

    flex-direction: column;

    .mobile-apps__download {
      margin-bottom: 3rem;

      .download-button {
        width: 90%;
        font-size: 0.8rem;
      }
    }

    .mobile-apps__image {
      width: 90%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1500px) {
    .mobile-apps__download {
      .heading {
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }

      .text {
        line-height: 2.5rem;
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
        width: 80%;
      }

      .download-button {
        padding: 1rem;
        border-radius: 0.5rem;
        margin-bottom: 0.8rem;
        width: 70%;
        font-size: 0.8rem;
      }
    }
    width: 75%;
    margin: 0 auto;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Space Grotesk", sans-serif;
  background-image: url("./assets/backgrounds/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 1000px; */
}

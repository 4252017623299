.nav-container {
  padding: 1.2rem;
  &__logo {
    width: 180px;
  }

  @media screen and (min-width: 768px) {
    padding: 1.5rem;
    &__logo {
      width: 200px;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 2rem 3rem;
    &__logo {
      width: 220px;
    }
  }

  @media screen and (min-width: 1440px) { 
    &__logo {
      width: 240px;
    }
  }
}

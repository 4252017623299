.waitinglist-container {
  background: #1a1e26;
  border-radius: 30px;
  border: 1px solid #e2c75b;
  color: #fff;
  padding: 2rem 1.5rem;

  &__top {
    align-self: center;

    &__heading {
      &__text {
        font-weight: 700;
        font-size: 1.8rem;
      }
      &__bar {
        width: 120px;
        height: 10px;
        margin-top: 0.7rem;
        background-color: #e2c75b;
      }
    }
    &__socials {
      margin-top: 1.5rem;
      display: flex;
      gap: 1rem;
    }
    &__body-text {
      color: #9ba5b2;
      font-size: 1.6rem;
      margin-top: 1.8rem;
      width: 80%;
    }

    &__form {
      margin-top: 1rem;

      input[type="number"] {
        -moz-appearance: textfield;
      }

      input {
        background-color: #1a1e26;
        padding: 1.2rem 2rem;
        width: 370px;
        outline: none;
        border: 2px solid #9da5b22b;
        border-radius: 10px;
        font-size: 1rem;
        color: #fff;
        margin-bottom: 2.5rem;

        &::placeholder {
          color: #9ba5b2;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &__submit {
        display: flex;
        justify-content: space-between;
        background: linear-gradient(87.75deg, #ad782d 1.89%, #e2c75b 96.74%);
        border-radius: 5px;
        width: 110px;
        padding: 0.7rem 2rem;
        font-size: 1.1rem;
        margin: 0 auto;
        margin-bottom: 2.5rem;
      }
    }
  }

  &__bottom {
    display: block;
    width: 450px;
    align-self: center;
  }

  @media screen and (min-width: 1700px) {
    justify-content: space-evenly;

    &__bottom {
      width: 600px;
    }
  }

  @media screen and (max-width: 1100px) {
    &__top {
      &__heading {
        &__text {
          font-size: 1.5rem;
        }
      }
      &__socials {
        gap: 0.5rem;
        &__icons {
          width: 40px;
        }
      }
      &__body-text {
        font-size: 1.2em;
      }
      &__form {
        input {
          width: 280px;
          padding: 1rem 1.8rem;
        }
      }
    }
    &__bottom {
      width: 340px;
    }
  }

  @media screen and (max-width: 880px) {
    input {
      width: calc(100% - 2.4rem);
      display: block;
      margin: 0 auto;
      margin-bottom: 2rem;
      padding: 1.2rem 1rem;
    }

    &__top {
      align-self: flex-start;

      &__body-text {
        width: 100%;
      }
    }

    flex-direction: column;
    &__bottom {
      margin-top: 7rem;
      width: 380px;
    }
  }

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 425px) {
    &__top {
      &__form {
        &__submit {
          margin-top: 1.5rem;
        }
      }
    }
    input {
      width: 290px;
    }

    &__bottom {
      width: 340px;
    }
  }

  @media screen and (max-width: 375px) {
    padding-top: 1rem;
    &__top {
      &__body-text {
        font-size: 1.1rem;
        width: 95%;
      }

      &__socials {
        gap: 0.5rem;
        &__icons {
          width: 100%;
        }
      }
      &__form {
        &__submit {
          margin-top: 1rem;
        }
      }
    }
    input {
      width: 80%;
      padding: 1rem 1rem;
    }
    &__bottom {
      width: 100%;
    }
  }

  @media screen and (min-width: 1800px) {
    background: #1a1e26;
    border-radius: 30px;
    border: 1px solid #e2c75b;
    color: #fff;
    padding: 2rem 2rem;

    &__top {
      align-self: center;

      &__heading {
        &__text {
          font-weight: 700;
          font-size: 1.8rem;
        }
        &__bar {
          width: 120px;
          height: 10px;
          margin-top: 0.7rem;
          background-color: #e2c75b;
        }
      }
      &__socials {
        margin-top: 1.5rem;
        display: flex;
        gap: 1rem;

        &__icons{
          width: 60px;
        }
      }
      &__body-text {
        color: #9ba5b2;
        font-size: 1.6rem;
        margin-top: 1.8rem;
        width: 80%;
      }

      &__form {
        margin-top: 1rem;

        input[type="number"] {
          -moz-appearance: textfield;
        }

        input {
          background-color: #1a1e26;
          padding: 1.2rem 2rem;
          width: 370px;
          outline: none;
          border: 2px solid #9da5b22b;
          border-radius: 10px;
          font-size: 1rem;
          color: #fff;
          display: block;
          margin: 0 auto;
          margin-bottom: 2.5rem;


          &::placeholder {
            color: #9ba5b2;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        &__submit {
          display: flex;
          justify-content: space-between;
          background: linear-gradient(87.75deg, #ad782d 1.89%, #e2c75b 96.74%);
          border-radius: 5px;
          width: 110px;
          padding: 0.7rem 2rem;
          font-size: 1.1rem;
          margin: 0 auto;
          margin-bottom: 4rem;
        }
      }
    }
  }
}

.section-heading {
  &__text {
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
  }

  .bar {
    width: 100px;
    height: 10px;
    background-color: #e2c75b;
  }

  @media screen and (min-width: 425px) {
    &__text {
      font-size: 1.6rem;
    }

    .bar {
      width: 150px;
      height: 12px;
      background-color: #e2c75b;
    }
  }
}
